export const getOrderBadge = status => {
  switch (status) {
    case 'reserved':
      return 'secondary';
    case 'wait':
      return 'secondary';
    case 'wait_cancel':
      return 'secondary';
    case 'pay':
      return 'primary';
    case 'address_wait':
      return 'primary';
    case 'ready':
      return 'primary';
    case 'delivery_wait':
      return 'primary';
    case 'delivery_start':
      return 'primary';
    case 'delivery_arrive':
      return 'primary';
    case 'delivery_confirm':
      return 'success';
    case 'cancel':
      return 'warning';
    case 'partial_cancel':
      return 'warning';
    case 'skip':
      return 'warning';
    case 'skip_cancel':
      return 'primary';
    case 'update_cancel':
      return 'primary';
    case 'refund':
      return 'danger';
    case 'partial_refund':
      return 'danger';
    case 'exchange':
      return 'danger';
    case 'fail':
      return 'danger';
    case 'unvalid':
      return 'danger';
    case 'expired':
      return 'danger';
    default:
      return '';
  }
};

export const getOrderLabel = status => {
  switch (status) {
    case 'reserved':
      return '예약';
    case 'wait':
      return '입금 대기중';
    case 'wait_cancel':
      return '가상계좌 입금 전 취소';
    case 'pay':
      return '상품준비중';
    case 'address_wait':
      return '선물 확인중';
    case 'ready':
      return '배송준비중';
    case 'delivery_wait':
      return '출고대기중';
    case 'delivery_start':
      return '배송중';
    case 'delivery_arrive':
      return '배송 완료';
    case 'delivery_confirm':
      return '수령 확인';
    case 'cancel':
      return '취소';
    case 'partial_cancel':
      return '부분 취소';
    case 'skip':
      return '쉬어가기';
    case 'skip_cancel':
      return '쉬어가기 취소';
    case 'update_cancel':
      return '예약변경';
    case 'refund':
      return '환불';
    case 'partial_refund':
      return '부분 환불';
    case 'exchange':
      return '교환';
    case 'fail':
      return '실패';
    case 'unvalid':
      return '유효하지 않음';
    case 'expired':
      return '입금만료';
    default:
      return '';
  }
};

export function onKeyDown(keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

export const BANK_OPTIONS = [
  {
    value: '004',
    option: 'KB국민은행',
  },
  {
    value: '023',
    option: 'SC제일은행',
  },
  {
    value: '039',
    option: '경남은행',
  },
  {
    value: '034',
    option: '광주은행',
  },
  {
    value: '003',
    option: '기업은행',
  },
  {
    value: '011',
    option: '농협',
  },
  {
    value: '031',
    option: '대구은행',
  },
  {
    value: '032',
    option: '부산은행',
  },
  {
    value: '002',
    option: '산업은행',
  },
  {
    value: '045',
    option: '새마을금고',
  },
  {
    value: '007',
    option: '수협',
  },
  {
    value: '088',
    option: '신한은행',
  },
  {
    value: '048',
    option: '신협',
  },
  {
    value: '005',
    option: '외환은행',
  },
  {
    value: '020',
    option: '우리은행',
  },
  {
    value: '071',
    option: '우체국',
  },
  {
    value: '037',
    option: '전북은행',
  },
  {
    value: '090',
    option: '카카오뱅크',
  },
  {
    value: '089',
    option: '케이뱅크',
  },
  {
    value: '081',
    option: '하나은행(서울은행)',
  },
  {
    value: '027',
    option: '한국씨티은행(한미은행)',
  },
];

export const checkKorean = text => {
  const normalizedText = text.normalize('NFC');
  const checkKoreanReg = /[\u3131-\u318E\uAC00-\uD7A3]/;
  const result = checkKoreanReg.test(normalizedText);

  return result;
};

export const trimStringInArray = array => {
  Object.keys(array).forEach(key => typeof array[key] === 'string' && (array[key] = array[key].trim()));

  // return array;
};

export const deliveryCompany = [
  'CJ대한통운',
  '로젠택배',
  '우체국택배',
  '등기우편',
  '한진택배',
  '롯데택배',
  '대신택배',
  '일양로지스',
  '경동택배',
  '합동택배',
  '천일택배',
  '편의점택배',
  '한의사랑택배',
  '건영택배',
  '호남택배',
  'SLX',
  'BGF포스트',
  '용마로지스',
  '세방택배',
  '원더스퀵',
  '농협택배',
  'HI택배',
  '홈픽택배',
  'CJ대한통운국제특송',
  'EMS',
  '롯데글로벌',
  '한덱스',
  'DHL Express',
  'DHL eCommerce',
  'FedEx',
  'FedEx SmartPost®',
  'UPS',
  'UPS Mail Innovations',
  'TNT',
  'USPS',
  'i-parcel',
  'GSMNtoN',
  '성원글로벌',
  '범한판토스',
  'ACI Express',
  '대운글로벌',
  '에어보이익스프레스',
  'KGL네트웍스',
  'LineExpress',
  '2fast익스프레스',
  'GSI익스프레스',
  'GTS로지스',
  'ECMS익스프레스',
  'EFS',
  'DHL GlobalMail',
  'GPS로직',
  '시알로지텍',
  '브리지로지스',
  '홈이노베이션로지스',
  'cway express',
  '자이언트',
  'ACE Express',
  '우리동네택배',
  '퍼레버택배',
  '워펙스',
  '큐익스프레스',
  '스마트로지스',
  '에스더쉬핑',
  '로토스',
  '은하쉬핑',
  '유프레이트 코리아',
  '엘서비스',
  '티피엠코리아',
  '제니엘시스템',
  '애니트랙',
  '제이로지스트',
  '두발히어로',
  '큐런',
  '프레시솔루션',
  '하이브시티',
  '한샘',
  '굿투럭',
  '지니고',
  '오늘의픽업',
  '노곡물류',
  '스페이시스원',
  'LTL',
  '다다스마트',
  '로지스팟',
  '프레시메이트',
  '부릉',
  'NK로지솔루션',
  '도도플렉스',
  '배송하기좋은날',
  '이투마스',
  '에이스물류',
  '캐나다쉬핑',
  '지디에이코리아',
  '바바바로지스',
  '팀프레시',
  '올타코리아',
  '롯데칠성',
  'yunda express',
  '발렉스',
  '술담화',
  '우리택배',
  'CU편의점택배',
];

export const initialOrderOptions = [
  { value: '', option: '전체' },
  { value: '1', option: '신규' },
  { value: '0', option: '기존' },
];

export const getProductBadge = status => {
  switch (status) {
    case 'active':
      return 'success';
    case 'ready':
      return 'secondary';
    case 'inactive':
      return 'warning';
    case 'sold_out':
      return 'danger';
    case 'deprecate':
      return 'dark';
    default:
      return 'primary';
  }
};

export const getProductStatusLabel = status => {
  switch (status) {
    case 'active':
      return '판매중';
    case 'ready':
      return '준비중';
    case 'inactive':
      return '비활성';
    case 'sold_out':
      return '품절';
    case 'deprecate':
      return '만료';
    default:
      return '?';
  }
};

export const MEMBERSHIP = [
  { level: 1, name: '옐로우' },
  { level: 2, name: '블루' },
  { level: 3, name: '퍼플' },
  { level: 4, name: '실버' },
  { level: 5, name: '골드' },
  { level: 6, name: 'VIP' },
  { level: 7, name: 'VVIP' },
];

export const LABEL_BOLD_STYLE = { marginBottom: '2px', fontWeight: 'bold', fontSize: '17px' };
